.diagram-line {
  stroke-width: 1.5;
  transition: 100ms;
  stroke: #99baeb;
}

.diagram-line > #add-plus {
  visibility: hidden;
}

.diagram-line:hover {
  stroke-width: 4;
  stroke-dasharray: 8;
}

.diagram-line:hover > #add-plus {
  visibility: visible;
}

.diagram-line > .selector-line {
  height: 100px;
  cursor: pointer;
  stroke: transparent;
  stroke-dasharray: 0;
  stroke-width: 40;
}
