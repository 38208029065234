.loading-size span {
  font-size: 50px;
}

.mt-10 {
  margin-top: 2rem;
}

.hazard-risk {
  color: rgba(114, 114, 117, 0.9);
  margin-top: 1.5rem;
}
